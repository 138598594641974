import Service from "../Service";

const resource = "FrzLotUnderObservation/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    EnableLot(obj, requestID) {
        return Service.post(resource + "enableLot", obj, {
            params: {requestID: requestID},
        });
    },
};