import Service from "../Service";

const resource = "frozenSampling/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    // buscar los detalles por la cabecera
    sampling(obj, requestID) {
        return Service.post(resource + "searchDetailS", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    samplingRPdf(obj, requestID) {
        return Service.post(resource + "samplingRPdf", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { requestID }
        });
    },

    send(cmp, requestID) {
        return Service.post(resource + "send", cmp, {
            params: { requestID: requestID },
        });
    },

    samplingSearchLlpID(obj, requestID) {
        return Service.post(resource + "searchsamplingllpid", obj, {
            params: { requestID: requestID },
        });
    },
};