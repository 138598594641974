<template>
    <div>
        <s-crud 
            title="Lotes en observacíon"
            :filter="filter"
            ref="crudLots"
            :config="config">

            <template v-slot:details="{ row }">
                <v-btn icon  x-small class="mr-2" @click="openDialog(row)" >
                     <v-icon>fas fa-eye</v-icon>
                </v-btn>
            </template>
           
            <template v-slot:actions="{ row }">
               
               <v-btn
                @click="EnableLot(row)"
                x-small
                class="pt-2 pb-2"
                color="success">
                    <!-- <v-icon >
                        mdi-arrow-alt-circle-right
                    </v-icon> -->

                    <i class="fas fa-arrow-alt-circle-right"></i>
                    
                </v-btn>
            </template>
          

        </s-crud>


        <!-- mostramos los detalles de ese lote observado -->
        <v-dialog
            v-model="modelDialog"
            persistent
            max-width="900"
            height="400px"
            v-if="modelDialog">

            <v-card>
                <s-toolbar     
                    label="DETALLES"
                    dark
                    close
                    @close="closeDialog()"
                    color="primary">
                </s-toolbar>

               <v-container>

                   <v-row>
                        <v-col>
                            <h2>Especificaciones tecnicas</h2>
                            <v-data-table
                                :items="itemsTable"
                                :headers="headerSpecifications"
                                disable-pagination
                                :hide-default-footer="true">

                                <template v-slot:item.Average="{ item }">
                                    <v-progress-linear
                                        height="25"
                                        color="amber"
                                    >
                                        <template>
                                            <strong>{{  item.Average }}</strong>
                                        </template>
                                    </v-progress-linear>
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-row>

               </v-container>
           
            </v-card>

            
        </v-dialog>

    </div>
</template>

<script>

import _sLotsUnderObservation from "@/services/FrozenProduction/FrzLotUnderObservation.js";
import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService.js"

import _sFrozenSampling from "@/services/FrozenProduction/FrzfrozenSampling.js";
import _sFrzTechnicalspecificationsByCultive from "@/services/FrozenProduction/FrzTechnicalSpecificationsByCultiveService.js";

export default {

    components : {

    },
    props: {

    }, 
    data :()=> ({

        items : [],
        itemHead: [],
        FfsID: 0,
        itemsDataBrix:[],
        itemsDataColor:[],
        itemsDataDamage:[],
        itemsDataMaturity:[],
        itemsDataFirmness: [],
        itemsDataTaste: [],
        itemsDataPh:[],
        itemsDataSize:[],
        itemsDataAcidity:[],
        modelDialog : false,
        filter : {
            DateBegin : '', 
            DateEnd	: ''
        }, 
        config: {
            model:{
                RcfID : "ID", 
                RcfDateReception : "date",
                details: "",
                actions: "",
            },
            service:_sLotsUnderObservation,
            headers: [
                { text:"Detalles", value: "details"},
                { text:"Acción", value: "actions"},
                { text: "Lote", value: "RcfID"},
                { text: "Fecha Recp", value: "RcfDateReception"},
                { text: "Cant. Jabas", value: "AmountJabasWeigh"},
                { text: "Peso Guia", value: "RcfWeightGuide"},
                { text: "Fundo", value:"FagName"},
                { text: "Productor", value : "PrdCardName"},
                { text: "Guia Remisión", value: "RcfNumberReferralGuide"},
                { text: "Guia cosecha", value: "RcfNumberHarvestGuide"},
                { text: "Tipo Cultivo", value : "TypeCultiveName"},
                { text: "Cultivo", value: "TypeCropName"},
                { text: "Variedad", value: "VrtName"},
                { text: "Tipo de servicio", value: "TypeServiceName"},

            ],
        },
        itemsDataSizeNewData : {},
        itemsDataMaturityNewData : {},
        itemsTable : [],
        itemsDefectByCultive : [],

        headerSpecifications : [
            { text: "Especificacion", value : "TypEspecificationsName"},
            { text: "Valor Minimo", value : "MinValue"},
            { text: "Valor Maximo", value : "MaxValue"},
            { text: "Valor del muestreo", value : "Average"}
        ]

    }),

    methods: {

        openDialog(item) {
            this.itemsTable = []
            this.items = item;
            this.modelDialog = true;
            this.getSpecification(this.items.TypeCultive);
            this.getDamageFlawCultive(this.items.TypeCultive);
            this.getSample();
            
        },

        EnableLot(item){
            console.log('obtenemos el objeto del lote', item);

            this.$fun.alert("Esta seguro de habilitar el Lote? ", "question").then((r)=>{
                if(r.value) {
                     _sLotsUnderObservation.EnableLot(item, this.$fun.getUserID())
                    .then((r)=>{
                        if(r.status == 200) {
                            this.$refs.crudLots.refresh();
                        }
                    });
                }
            });
        },

        closeDialog() {
            this.modelDialog = false;
        },

        getDamageFlawCultive(cultive) {
            // console.log('Tipo cultivo daños', cultive)
            let obj = {
                TypeCultive : cultive
            };
            // cultive.TypeCultive = parseInt(cultive);
            _sDamageFlawCultiveService
            .list(obj, this.$fun.getUserID())
            .then(resp => {
                if(resp.status == 200)
                {
                    this.itemsDefectByCultive = resp.data;
                    console.log("list daños david", resp.data);
                }
            })
        },

        getSample() {
            
            let obj = {
                TypeShip: 2,
                MtpStatus: 0,
                RcfID: this.items.RcfID,
                PseID: this.items.RcfID,
                FfsID: 0,
                MtpID: 0,
            };

            _sFrozenSampling.sampling(obj, this.$fun.getUserID()).then((r) => {

                if (r.data != null) {

                 console.log('datos de muestra',r.data) 

                this.FfsID = r.data.FfsID;
                this.itemHead = r.data;
                this.itemsDataBrix = r.data.DataBrixFrozen;
                this.itemsDataColor = r.data.DataColorFrozen;
                this.itemsDataDamage = r.data.DataDamageFrozen;
                this.itemsDataMaturity = r.data.DataMaturityFrozen;
                // console.log('Datos maduration', this.itemsDataMaturity);
                this.itemsDataSize = r.data.DataSizeFrozen;
               
                this.itemsDataAcidity = r.data.DataAcidityFrozen;
               

                this.getValues();
                this.getValuesDamage();

                } else {

                this.FfsID = 0;
                this.itemHead = {};
                this.itemsDataBrix = [];
                this.itemsDataColor = [];
                this.itemsDataMaturity = [];
                this.itemsDataDamage = [];
                this.itemsDataSize = [];
                this.itemsDataAcidity = [];
                this.isEdit = false;
                this.itemHead.FfsIsValid = 1;
                }
            });
        },

        getValues(){


              // CALCULAMOS EL PROMEDIO DE MATERIA SECA
            let QunatitySize=0, acountSize=0;
            this.itemsDataSize.forEach((element)=>{
                QunatitySize = (QunatitySize + parseFloat(element.SzfValue));
                acountSize++;
            });
            this.itemsDataSizeNewData.Average = (QunatitySize/acountSize);
            this.itemsDataSizeNewData.isvisible = false;
            // console.log('promedio de materia seca', this.itemsDataSizeNewData.Average )

            // CALCULAMOS EL PROMEDIO DE MADUREZ
            let QunatityMaturity =0, acountMaturity = 0;
            this.itemsDataMaturity.forEach((element)=>{
                QunatityMaturity = (QunatityMaturity + parseFloat(element.FmfValue));
                acountMaturity++;
            });
            this.itemsDataMaturityNewData.Average = (QunatityMaturity/acountMaturity);
            this.itemsDataSizeNewData.isvisible = false;
            // console.log('promedio de maduracion', this.itemsDataMaturityNewData.Average);



            this.itemsSpecificationsCultive.forEach((element)=>{
                if(element.TypEspecifications == 8) {

                    if(this.itemsDataSizeNewData.Average > element.FsaMaxValue || this.itemsDataSizeNewData.Average < element.FsaMinValue){
                        let value = {};
                        value.TypEspecifications = 8;
                        value.TypEspecificationsName = element.TypEspecificationsName;
                        value.Average = this.itemsDataSizeNewData.Average;
                        value.MaxValue = element.FsaMaxValue;
                        value.MinValue = element.FsaMinValue;

                        this.itemsTable.push(value);

                        }
                }

                if(element.TypEspecifications == 4) {

                    if(this.itemsDataMaturityNewData.Average > element.FsaMaxValue || this.itemsDataSizeNewData.Average < element.FsaMinValue){
                        let value = {};
                        value.TypEspecifications = 4;
                        value.TypEspecificationsName = element.TypEspecificationsName;
                        value.Average = this.itemsDataMaturityNewData.Average;
                        value.MaxValue = element.FsaMaxValue;
                        value.MinValue = element.FsaMinValue;
                        this.itemsTable.push(value);
                    }
                }
            });

        //   console.log('tabla de datos', this.itemsTable);


        },

        getValuesDamage () {

            this.itemsDataDamage.forEach((element)=>{
                this.itemsDefectByCultive.forEach((x)=>{
                    if(x.DfcID == element.DfcID ) {
                        console.log('tiene este daño', x)
                        let value = {};
                        value.TypEspecifications = x.DfcID;
                        value.TypEspecificationsName = element.DfcName;
                        value.Average = element.FdfPercentage;
                        value.MaxValue = 0;
                        value.MinValue = x.DfcTolerance;

                        this.itemsTable.push(value);
                    }
                })
            });
        },


        getSpecification(cultive){
	
            let obj = {
                TypeCultive : cultive,
            };
            _sFrzTechnicalspecificationsByCultive.listByCultive(obj, this.$fun.getUserID())
            .then((r)=>{
                if(r.status == 200) {
                    // console.log('mostramos las especificaciones', r.data);
                    this.itemsSpecificationsCultive = r.data;
                }
            });
        },


    },

       
}

</script>
